import React from "react";
import { ResponsiveLine } from '@nivo/line';
import {
  Message, Header, Grid
} from "semantic-ui-react";
import { getHistoricalPrices } from "../../Store/actions/CoinbaseAPI";
import { connect } from "react-redux";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.



const Chart = props => (

    <ResponsiveLine
        data={props.data}
        enableSlices="x"
        margin={{ top: 10, right: 40, bottom: 30, left: 50 }}
        xScale={{
                  type: 'time',
                  format: '%Y-%m-%d',
                  useUTC: true,
                  precision: 'hour',
                }}
        xFormat="time:%b-%d-%Y"
        yScale={{
                  type: 'linear',
                  min: 'auto',
                  max: 'auto',
                  stacked: false,
                  reverse: false
                }}
        yFormat=">-$,.2f"
        curve="linear"
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: 'left',
          tickSize: 0,
          tickPadding: 15,
          format: '>-$,.2f',
          tickValues: 4,

        }}
        axisBottom={{
            format: '%b',
            tickValues: 'every month',

        }}

        colors={[ '#282c34' ]}
        borderColor="#000000"
        lineWidth={5}
        enablePoints={false}
        enableGridX={false}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        areaOpacity={0}
        useMesh={true}
        animate={false}
        legends={[]}
        theme={{
          "fontSize": 14,
          "textColor": "#797B80",

          "grid": {
            "line": {
                "stroke": "#dddddd",
                "strokeWidth": .25
              }
          }
        }}
        sliceTooltip={({ slice }) => {
              return (
                  <div
                      style={{
                          background: 'white',
                          padding: '10px 12px',
                          border: '1px solid #ccc',
                      }}
                  >

                      {slice.points.map(point => (

                          <div
                              key={point.id}
                              style={{
                                  color: point.serieColor,
                                  padding: '3px 0',
                              }}
                          >
                              {point.data.xFormatted}<br/>
                              <strong>{point.data.yFormatted}</strong>
                          </div>
                      ))}
                  </div>
              )
          }}
    />
  );

  class PriceChart extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        chartData:[{id:"",data:[{'x':0,'y':0}]}],
        days:0,
        startDate:this.handleFormatDate(300),
        endDate:this.handleFormatDate(0),
        activeCCY:"ADA-USD",
        granularity: 86400,
      };

    }

    handleFormatDate = (days)=> {
      if (days === undefined) { days = 0}
      const endDate= new Date()
      const beginDate = new Date(endDate - (days * 86400000))
      return encodeURI(beginDate.toISOString().split('.')[0])
    };

    componentDidMount() {
        const {activeCCY, startDate, endDate, granularity} = this.state
        this.props.getHistoricalPrices(activeCCY, startDate, endDate, granularity);
        
    };

    componentWillUnmount() {
    console.log();
    }

    render() {
      const { priceData, callError, callLoading} = this.props;

      return (
        <Grid>
        <Grid.Row columns={2} >
          <Grid.Column>
            <Header>ADA-USD</Header>
          </Grid.Column>
          <Grid.Column textAlign='right'>

          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div style={{height: "200px", padding:"0px 0px",}}>
            {callError ? (
              <Message
                icon='chart line'
                header="Error retireving data"
                content="Please try again"
              />
            ):(

            <Chart data={priceData}/>
            )}
            </div >
            </Grid.Column>
        </Grid.Row>
        </Grid>
      );
    }
  }

  const mapDispatchToProps = dispatch => {

  };

  const mapStateToProps = state => {
    return {
      priceData: state.coin.priceData,

      callLoading: state.coin.loading,
      callError: state.coin.error,
    };
  };

  export default connect(
    mapStateToProps,
    { getHistoricalPrices }
  )(PriceChart);
