import React from "react";
import { Route, Switch } from "react-router-dom";


import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Status from "./Pages/Status/Status";
import About from "./Pages/About/About";


export const BaseRouter = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/login" component={Login} />
    <Route path="/status" component={Status} />
    <Route path="/about" component={About} />
  </Switch>
);
