import React, { useRef } from 'react'
import { connect } from "react-redux";
import { Parallax, ParallaxLayer } from '@react-spring/parallax'

import {

  Image,

} from "semantic-ui-react";

import MainSegment from "../../Components/MainSegment/MainSegment";
import { MainTitle } from "../../Components/MainTitle/MainTitle";
import { StatBox } from "../../Components/StatBox/StatBox";

import HowTo from "../../Components/HowTo/HowTo";
import { toggleModal } from "../../Store/actions/main";
import './Home.css'

// Little helpers ...
const url = (name: string, wrap = false) =>
  `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`



function Home(props) {
  const parallax = useRef()


  return (



    <React.Fragment>
    <div style={{ width: '100%', height: '100%', background: '#253237' }}>

      <HowTo />

      <Parallax ref={parallax} pages={3}>

        <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: '#334753' }} />
        <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#87BCDE' }} />

        <ParallaxLayer
          offset={0}
          speed={0}
          factor={3}
          style={{
            backgroundImage: url('stars', true),
            backgroundSize: 'cover',
          }}
        />

        <ParallaxLayer offset={1.6} speed={-0.3} style={{ pointerEvents: 'none' }}>
          <Image src={`${process.env.PUBLIC_URL}/assets/img/cardano-logo.svg`} style={{ width: '10%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 0.1 }}>
          <Image src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '55%' }} />
          <Image src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '15%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.1 }}>
          <Image src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '70%' }} />
          <Image src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '40%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.2 }}>
          <Image src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '10%' }} />
          <Image src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.4 }}>
          <Image src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '60%' }} />
          <Image src={url('cloud')} style={{ display: 'block', width: '25%', marginLeft: '30%' }} />
          <Image src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '80%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.6 }}>
          <Image src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '5%' }} />
          <Image src={url('cloud')} style={{ display: 'block', width: '15%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2.5}
          speed={-0.4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',

          }}>
          <Image src={`${process.env.PUBLIC_URL}/assets/img/cardano.png`} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={-0.3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >

        </ParallaxLayer>

        <ParallaxLayer
          offset={0}
          speed={0.1}
          onClick={() => parallax.current.scrollTo(1)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
        
          <MainTitle/>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={0.1}
          onClick={() => parallax.current.scrollTo(2)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>

          <MainSegment />

        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={-0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => parallax.current.scrollTo(0)}>
          <StatBox/>

        </ParallaxLayer>

      </Parallax>
    </div>
    </React.Fragment>
  )
}

const mapState = state => {

  return {
    open: state.main.open,


  };
};

const mapDispatch = dispatch => {
  return {
    toggleModal: () => dispatch(toggleModal()),

  };
};

export default connect(mapState, mapDispatch)(Home)
