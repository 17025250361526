import React from 'react'
import {
  Button,

  Header,

  Icon,


  Sidebar,
  Menu
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../Store/actions/auth";
import { toggleModal, toggleSidebar, hideSidebar } from "../../Store/actions/main";

import './SideMenu.css'
import { connect, useDispatch } from "react-redux";


function SideMenu (props) {
  const dispatch = useDispatch();
  const history = useHistory();

  function test (page) {

    dispatch(toggleSidebar());
    console.log(props.sidebar);
    history.push(page);
  }

  function handleInfo () {
    dispatch(toggleSidebar());
    dispatch(toggleModal());
    console.log(props.sidebar);
    history.push('/');
  }


  return (
    <Sidebar
          as={Menu}
          direction='right'
          animation='overlay'
          icon='labeled'
          vertical
          onHide={()=>dispatch(hideSidebar())}
          visible={props.sidebar}
          width='wide'
          className='sidebar'
    >
      <div className='sidebar-exit'>
      <Icon name="arrow left" size='large' onClick={()=>dispatch(hideSidebar())}/>
      </div>
      <Header as="h3" textAlign="center" style={{fontFamily: 'Bebas Neue'}}>
      STACK CHEDDAR
      </Header>
       <Menu.Item onClick={()=>test('/about')}>

         About

       </Menu.Item>
       {props.authenticated ? (
       <React.Fragment>
         <Menu.Item onClick={()=>dispatch(logout())}>

           Logout

         </Menu.Item>
         <Menu.Item onClick={()=>test('/status')}>

           Status

         </Menu.Item>
         </React.Fragment>
         ):(
         <Menu.Item onClick={()=>test('/login')}>

           Login

         </Menu.Item>

       )
       }

       <Menu.Item >
         <Button onClick={() => handleInfo()}>
         Start Delegating
         </Button>
       </Menu.Item>

     </Sidebar>
  )
};

const mapState = state => {
  return {
    authenticated: state.auth.token !== null,
    sidebar: state.main.sidebar,
  };
};

const mapDispatch = dispatch => {
  return {
    logout: () => dispatch(logout()),
    toggleSidebar: () => dispatch(toggleSidebar()),
    hideSidebar: () => dispatch(hideSidebar()),
    toggleModal: () => dispatch(toggleModal()),
  };
};

export default connect(mapState, mapDispatch)(SideMenu)
