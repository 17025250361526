import React from 'react'
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toggleModal, toggleSidebar } from "../../Store/actions/main";
import {
  Button,
  Container,
  Header,
  Grid,
  Icon,
  List,
  Image,
  Segment,
} from "semantic-ui-react";
import './MainSegment.css'

function MainSegment (props) {
  const dispatch = useDispatch();

  return(
  <Segment textAlign='left' className="main-segment" >
  <Grid container stackable style={{ padding: "2em 0em" }}>
  <Grid.Row columns={2} verticalAlign='middle'>
  <Grid.Column width={10}>
    <Grid.Row>
    <Grid.Column>
    <Container>
    <Header as="h2" style={{fontFamily: 'Bebas Neue'}}>
    Stack up on rewards with us!
    </Header>
    <div >
    Delegate your ADA for rewards and support the world's leading, fully decentralized currency!
    <br/><br/>We run our own servers, supported by finance and development professionals:
    </div>
    <div className="segment-list">
    <List size='large' >
      <List.Item>
        <Icon name='lock' />
        <List.Content>
          <List.Header>Reinforced security & firewall</List.Header>
        </List.Content>
      </List.Item>
      <List.Item>
        <Icon name='battery four' />
        <List.Content>
          <List.Header>Generator backup</List.Header>
        </List.Content>
      </List.Item>
      <List.Item>
        <Icon name='power' />
        <List.Content>
          <List.Header>Energy efficient servers</List.Header>
        </List.Content>
      </List.Item>
    </List>
    </div>
    </Container>

    </Grid.Column>




  </Grid.Row>

  </Grid.Column>
  <Grid.Column width={6} textAlign='center' >

  <List horizontal size='large' style={{ flexWrap: 'wrap',wordWrap: 'break-word' }}>
    <List.Item>
      <List.Content>
        <List.Header>Ticker</List.Header>
        CHDR
      </List.Content>
    </List.Item>

    <List.Item>
      <List.Content>
        <List.Header>Fixed Fee</List.Header>
        340 ADA
      </List.Content>
    </List.Item>

    <List.Item>
      <List.Content>
        <List.Header>Variable Fee</List.Header>
        2%
      </List.Content>
    </List.Item>
  </List>
  <Button fluid onClick={() => dispatch(toggleModal())} color="black" style={{ margin: "1em 0em" }}>Start Delegating</Button>
  </Grid.Column>
  </Grid.Row>
  </Grid>
  </Segment>
)
}

const mapState = state => {
  return {
    open: state.main.open,
  };
};

const mapDispatch = dispatch => {
  return {
    toggleModal: () => dispatch(toggleModal()),
  };
};

export default connect(mapState, mapDispatch)(MainSegment)
