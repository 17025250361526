import React from 'react'
import {
  Header,
  Grid,
  Image,
  Segment,
  Statistic
} from "semantic-ui-react";
import './StatBox.css'
import PriceChart from "../Chart/Chart";
import { createMedia } from "@artsy/fresnel"
const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
})

export const StatBox = () => (
  <MediaContextProvider>
  <Segment className="stat-segment">

  <Grid container stackable >
  <Grid.Row stretched>
  <Grid.Column >
  <PriceChart />
  </Grid.Column>

  </Grid.Row>
  </Grid>
  </Segment>
  </MediaContextProvider>
)

export const StatMobile = (props) => (

  <Statistic.Group size="small" widths={3}>
     <Statistic size="tiny">
       <Statistic.Value>4201</Statistic.Value>
       <Statistic.Label>Epoch</Statistic.Label>
     </Statistic>


       <Statistic size="tiny">
         <Statistic.Value>2</Statistic.Value>
         <Statistic.Label>Delegators</Statistic.Label>
       </Statistic>

     <Statistic size="tiny">
       <Statistic.Value>₳15k</Statistic.Value>
       <Statistic.Label>Live Stake</Statistic.Label>
     </Statistic>
     </Statistic.Group>

)

export const StatDesktop = (props) => (
  <Grid>
    <Grid.Row >
    <Grid.Column>
    <Header as="h3" style={{fontFamily: 'Bebas Neue'}}>
      <Image size='mini' src={`${process.env.PUBLIC_URL}/assets/img/cheese-flat.png`} />
      Stack Cheddar
    </Header>
    </Grid.Column>
   </Grid.Row>
   <Grid.Row verticalAlign="middle">
   <Grid.Column>

    </Grid.Column>
    </Grid.Row>
  </Grid>
)
