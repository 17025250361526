
import React, { useEffect } from 'react'

import { connect, useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { BaseRouter } from "./Router";
import TopNav from "./Components/TopNav/TopNav";
import { MainContainer } from "./Containers/MainContainer/MainContainer";
import 'semantic-ui-css/semantic.min.css'
import * as actions from "./Store/actions/auth";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Update the document title using the browser API
    dispatch(actions.authCheckState());
    },
    [dispatch])

  return (
    <Router>
    <TopNav/>
    <MainContainer>
      <BaseRouter/>
    </MainContainer>
    </Router>
  );
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.token !== null,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),


  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
