import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  priceData: [{id:"ADA-USD",data:[{'x':'2020-01-01','y':0}]}],
  error: null,
  loading: false
};

const historicalPriceStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const historicalPriceSuccess = (state, action) => {
  return updateObject(state, {
    priceData: action.data,
    error: null,
    loading: false
  });
};

const historicalPriceFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HISTORICAL_PRICES_CALL_START:
      return historicalPriceStart(state, action);
    case actionTypes.HISTORICAL_PRICES_CALL_FAILED:
      return historicalPriceFail(state, action);
    case actionTypes.HISTORICAL_PRICES_CALL_SUCCESS:
      return historicalPriceSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
