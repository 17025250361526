
import * as actionTypes from "./actionTypes";


export const toggleModal = () => {
  return {
    type: actionTypes.TOGGLE_MODAL

  };

};

export const toggleSidebar = () => {
  return {
    type: actionTypes.TOGGLE_SIDEBAR

  };

};

export const hideSidebar = () => {
  return {
    type: actionTypes.HIDE_SIDEBAR

  };

};
