
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";

export const HISTORICAL_PRICES_CALL_START = "HISTORICAL_PRICES_CALL_START"
export const HISTORICAL_PRICES_CALL_FAILED = "HISTORICAL_PRICES_CALL_FAILED"
export const HISTORICAL_PRICES_CALL_SUCCESS = "HISTORICAL_PRICES_CALL_SUCCESS"

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const STATUS_START = "STATUS_START";
export const STATUS_SUCCESS = "STATUS_SUCCESS";
export const STATUS_FAIL = "STATUS_FAIL";
