import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import mainReducer from "./Store/reducers/main";
import coinReducer from "./Store/reducers/CoinbaseAPI";
import authReducer from "./Store/reducers/auth";
import statusReducer from "./Store/reducers/status";

const rootReducer = combineReducers({
  main: mainReducer,
  coin: coinReducer,
  auth: authReducer,
  status:statusReducer
});

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhances(applyMiddleware(thunk)));

ReactDOM.render(

    <Provider store={store}>
    <App />
    </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
