import React, { useEffect } from 'react'
import { connect, useDispatch } from "react-redux";

import {

  Header,
  Grid,
  Icon,

  Segment,
  Statistic,

  Message,
  Label,

} from "semantic-ui-react";
import { fetchStatus } from "../../Store/actions/status";
import Moment from 'moment';
import './Status.css'
import { css } from "@emotion/react";
import Loader from "react-spinners/ScaleLoader";

// Little helpers ...
const url = (name: string, wrap = false) =>
  `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`

function nodeType(type){
  switch (type) {
  case 'R':
    return 'rss';
  case 'P':
    return 'circle notch';
  default:
    return 'cog';
  }
}

function checkDate(date){

  let mins = Moment().diff(Moment(date), 'minutes');
  if (mins < 5) {
    return "green"
  }
  else{
    return "red"
  }

}

function Status(props) {

  const dispatch = useDispatch();

  useEffect(() => {
    // Update the document title using the browser API
    dispatch(fetchStatus());
    },
    [dispatch])

  if (!props.authenticated) {
  props.history.push('/');
  }

  return (

    <div style={{ width: '100%', height: '100%', background: '#253237', backgroundImage: url('stars', true), }}>

    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' container>
    <Grid.Column style={{ maxWidth: 650 }}>
    {props.error && (
      <Message>Error</Message>

    )}
    {props.loading ? (
      <Loader color="#FFFFFF" loading={props.loading}  />
    ):(
      <div>

      {props.status && (
      <div>
      {props.status.map((machine, i) => {
        return(
        <Segment textAlign='left' key={i} className="status-segment">
        <Grid columns='equal' stackable>
        <Grid.Row>
        <Grid.Column width={11}>
        <Header as='h4'>
          <Icon name={nodeType(machine.type)} />
          <Header.Content>
            {machine.name}
            <Header.Subheader>{machine.location}</Header.Subheader>
          </Header.Content>
        </Header>
        </Grid.Column>
        <Grid.Column width={5}>
        <Label color={checkDate(machine.current_status.datetime)}>
          <Icon name='time' />
          {Moment(machine.current_status.datetime).format('MMM D YYYY h:mm a')}
        </Label>
        </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column>
        <Statistic.Group size="tiny" widths={4} >
        <Statistic>
          <Statistic.Value>
            <Icon color='black' name='microchip' /> {machine.current_status.cpu_pct.toFixed(1)}%
          </Statistic.Value>
          <Statistic.Label>cpu</Statistic.Label>
        </Statistic>
        <Statistic >
          <Statistic.Value>
            <Icon color='black' name='server' /> {machine.current_status.mem_pct.toFixed(1)}%
          </Statistic.Value>
          <Statistic.Label>RAM</Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value>
            <Icon name='disk' /> {machine.current_status.ssd_pct.toFixed(1)}%
          </Statistic.Value>
          <Statistic.Label>SSD</Statistic.Label>
        </Statistic>
        <Statistic>
          <Statistic.Value>
            <Icon name='thermometer' /> {machine.current_status.cpu_temp.toFixed(1)}&deg;C
          </Statistic.Value>
          <Statistic.Label>Temp</Statistic.Label>
        </Statistic>
        </Statistic.Group>
        </Grid.Column>
        </Grid.Row>
        </Grid>
        </Segment>
      )
        })
      }
      </div>
      )}
      </div>
      )}


    </Grid.Column>
    </Grid>

    </div>

  )
};

const mapState = state => {

  return {
    loading: state.status.loading,
    error: state.status.error,
    status: state.status.status,
    authenticated: state.auth.token !== null,
  };
};

const mapDispatch = dispatch => {
  return {
    fetchStatus: () => dispatch(fetchStatus())
  };
};

export default connect(mapState, mapDispatch)(Status)
