import { STATUS_START, STATUS_SUCCESS, STATUS_FAIL } from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  status: null,
  error: null,
  loading: false
};


const statusStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const statusSuccess = (state, action) => {
  return updateObject(state, {
    status: action.data,
    error: null,
    loading: false
  });
};

const statusFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_START:
      return statusStart(state, action);
    case STATUS_SUCCESS:
      return statusSuccess(state, action);
    case STATUS_FAIL:
      return statusFail(state, action);
    default:
      return state;
  }
};

export default reducer;
