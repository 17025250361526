import * as actionTypes from "./actionTypes";
import { productHistoryURL } from "../../constants";
import axios from "axios";
import moment from "moment";

function handleFormatData(data,productID) {
  const dataSorted = data.reverse()
  const firstValue = dataSorted[0][4]
  const arr = [{
  id:productID,
  data: dataSorted.map((a) => (
    { x: moment.utc(moment.unix(a[0])).format("YYYY-MM-DD"),
      y: a[4].toFixed(2),
      test: moment.utc(moment.unix(a[0])).format("YYYY-MM-DDTHH:mm:ss"),
    })),

  }];
    return arr;
};

export const historicalPricesStart = () => {
  return {
    type: actionTypes.HISTORICAL_PRICES_CALL_START,

  };
};

export const historicalPricesCallFailed = error => {
  return {
    type: actionTypes.HISTORICAL_PRICES_CALL_FAILED,
    error: error
  };
};

export const historicalPricesCallSuccess = data => {
  return {
    type: actionTypes.HISTORICAL_PRICES_CALL_SUCCESS,
    data: handleFormatData(data,"ADA-USD")
  };
};

export const getHistoricalPrices = (ccy, startDate, endDate, granularity) => {
  return dispatch => {
    dispatch(historicalPricesStart());

    axios
      .get(productHistoryURL(ccy),
        {params:
          {
          start: startDate,
          end: endDate,
          granularity: granularity,
          }
          })
      .then(res => {
        dispatch(historicalPricesCallSuccess(res.data));

      })
      .catch(err => {
        dispatch(historicalPricesCallFailed(err.message));
        console.log(err.message)
      });
  };
};
