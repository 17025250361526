import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  open: false,
  sidebar: false,
  error: null,
  loading: false
};


const toggleModal = (state, action) => {
  return updateObject(state, {
    open: !state.open,
  });
};

const toggleSidebar = (state, action) => {
  return updateObject(state, {
    sidebar: !state.sidebar,
  });
};

const hideSidebar = (state, action) => {
  return updateObject(state, {
    sidebar: false,
  });
};



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_MODAL:
      return toggleModal(state, action);
    case actionTypes.TOGGLE_SIDEBAR:
      return toggleSidebar(state, action);
    case actionTypes.HIDE_SIDEBAR:
        return hideSidebar(state, action);
    default:
      return state;
  }
};

export default reducer;
