const localhost = `http://127.0.0.1:8000/`
const apihost = `https://api.cheddarpool.net`;


export const endpoint = `${apihost}`;

export const loginAPI = `${endpoint}/rest-auth/login/`;
export const registrationAPI = `${endpoint}/rest-auth/registration/`;
export const ccyBasket = ["BTC-USD","ETH-USD", "XRP-USD"]
export const statusURL = `${endpoint}/status/?machine=relay1101`;
export const machineURL = `${endpoint}/machine/`;

export const coinbase = `https://api.pro.coinbase.com`;
export const sandbox = `https://api-public.sandbox.pro.coinbase.com`;

export const productHistoryURL = id => `${coinbase}/products/${id}/candles`;
export const productListURL = `${coinbase}/products/`;
export const BTCHistoryURL =  `${sandbox}/products/BTC-USD/candles`;
