import React from 'react'
import { connect } from "react-redux";

import { animated, useSpring} from 'react-spring'

import {
  Header,
  Grid,
  Segment
} from "semantic-ui-react";
import { fetchStatus } from "../../Store/actions/status";

import './About.css'
import { css } from "@emotion/react";
import Loader from "react-spinners/ScaleLoader";

// Little helpers ...
const url = (name: string, wrap = false) =>
  `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`



function About(props) {

  const { x } = useSpring({ from: { x: 0 }, x: 1, config: { duration: 1000 } })

  return (

    <div style={{ width: '100%', height: '100%', background: '#253237', backgroundImage: url('stars', true), }}>

    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' container>
    <Grid.Column style={{ maxWidth: 650 }}>
    <div >
      <animated.div id="introHeader"
        style={{
          opacity: x.interpolate({ range: [0, 1], output: [0.3, 1] }),
          transform: x
            .interpolate({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
              output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
            })
            .interpolate(x => `scale(${x})`)
        }}>
        <Segment textAlign='left' className="status-segment">
        <Grid >
        <Grid.Column >
        <Grid.Row>

        <Header as="h2" style={{fontFamily: 'Bebas Neue',marginBottom:"1em"}}>
        Stack Cheddar Forever!
        </Header>
        </Grid.Row>
        <Grid.Row >
        <p style={{ textAlign:'justify' }}>
        At Stack Cheddar we are focused on maintaining network nodes and developing projects built on the Cardano blockchain. We created the Stack Cheddar stake pool to support the Cardano network, the world's leading, fully decentralized currency.
        <br/><br/>Our experience in finance and software development gives us an edge to operate and maintain the most secure and reliable stakepool possible.
        We run our own secured, generator backed, energy efficient servers.
        <br/><br/><b>Stake with us and start earning rewards!!!</b>
        </p>

        </Grid.Row>
        <Grid.Row style={{marginTop:"1em"}}>
        <p>
        Email:
        <a href="mail.to:stack@cheddarpool.net"> stack@cheddarpool.net</a>
        </p>

        </Grid.Row>
        </Grid.Column>
        </Grid>
        </Segment>
        </animated.div>
      </div>

    </Grid.Column>
    </Grid>

    </div>

  )
};

const mapState = state => {

  return {
    loading: state.status.loading,
    error: state.status.error,
    status: state.status.status,
    authenticated: state.auth.token !== null,
  };
};

const mapDispatch = dispatch => {
  return {
    fetchStatus: () => dispatch(fetchStatus())
  };
};

export default connect(mapState, mapDispatch)(About)
