import React, { useState } from 'react'
import { connect, useDispatch } from "react-redux";

import { animated, useSpring} from 'react-spring'

import {
  Button,
  Grid,
  Segment,
  Form,
  Message
} from "semantic-ui-react";
import { authLogin } from "../../Store/actions/auth";
import Loader from "react-spinners/ScaleLoader";
// Little helpers ...
const url = (name: string, wrap = false) =>
  `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`

function Logo(props) {
  const [inputs, setInputs] = useState({
        email : "",
        password : ""
    })
  const dispatch = useDispatch();
  const { x } = useSpring({ from: { x: 0 }, x: 1, config: { duration: 1000 } })

  const handleChange = e => {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  };

  const handleSubmit = e => {
      e.preventDefault();
      const { email, password } = inputs;
      dispatch(authLogin(email, password));
    };

  return (
    <div >
      <animated.div id="introHeader"
        style={{
          opacity: x.interpolate({ range: [0, 1], output: [0.3, 1] }),
          transform: x
            .interpolate({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
              output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
            })
            .interpolate(x => `scale(${x})`)
        }}>
        <Segment style={{ background: '#8C93A5', opacity:0.75 }}>
        <Form size='small' onSubmit={handleSubmit}>
          <Form.Field
          name="email"
          placeholder="email"
          control='input'
          onChange={handleChange}>
          </Form.Field>
          <Form.Field
          name="password"
          placeholder="password"
          type="password"
          control='input'
          onChange={handleChange}>
          </Form.Field>

          <Button basic
            fluid
            size="small"
          >
            Login
          </Button>
        </Form>
        </Segment>
      </animated.div>
    </div>
  )
}



function Login(props) {

  if (props.token) {
  props.history.goBack();
  }
  return (

    <div style={{ width: '100%', height: '100%', background: '#253237', backgroundImage: url('stars', true), }}>
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' container>
    <Grid.Column style={{ maxWidth: 450 }}>
    {props.loading ? (
      <Loader color="#FFFFFF" loading={props.loading}  />
    ):(
    <Logo/>
    )}
    </Grid.Column>
    </Grid>
    </div>
  )
};

const mapState = state => {

  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token

  };
};

const mapDispatch = dispatch => {
  return {
    login: (email, password) => dispatch(authLogin(email, password))

  };
};

export default connect(mapState, mapDispatch)(Login)
