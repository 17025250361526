import { STATUS_START, STATUS_SUCCESS, STATUS_FAIL } from "./actionTypes";

import { machineURL } from "../../constants";
import axios from "axios";

export const statusStart = () => {
  return {
    type: STATUS_START
  };
};

export const statusSuccess = data => {
  return {
    type: STATUS_SUCCESS,
    data
  };

};

export const statusFail = error => {
  return {
    type: STATUS_FAIL,
    error: error
  };
};



export const fetchStatus = () => {
  return dispatch => {
    dispatch(statusStart());
    axios
      .get(machineURL)
      .then(res => {
        dispatch(statusSuccess(res.data));

        })
      .catch(err => {
        dispatch(statusFail(err));
      });
  };
};
