import axios from "axios";
import * as actionTypes from "./actionTypes";

import { loginAPI, registrationAPI } from "../../constants";


export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return dispatch => {
    dispatch(
    {type: actionTypes.AUTH_SUCCESS,
    token: token
    });
    localStorage.getItem("token");

    };
  };


export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  return dispatch => {
    dispatch(
      {type: actionTypes.AUTH_LOGOUT});

  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const authLogin = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(loginAPI, {
        email: email,
        password: password
      })
      .then(res => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 43200 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(43200));


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data.non_field_errors);
          dispatch(authFail(error.response.data.non_field_errors));
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          dispatch(authFail(error.message));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

      });
  };
};

export const authSignup = (email, first_name, last_name, password1, password2) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(registrationAPI, {
        email: email,
        first_name: first_name,
        last_name: last_name,
        password1: password1,
        password2: password2
      })
      .then(res => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 43200 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.getItem("token");
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(43200));

      })
      .catch(err => {
        dispatch(authFail(err));
      });
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    if (token === undefined) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
