import React, { useState } from 'react'
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleModal, toggleSidebar } from "../../Store/actions/main";
import { logout } from "../../Store/actions/auth";
import { createMedia } from "@artsy/fresnel"
import {
  Container,
  Header,
  Image,
  Menu,
  Icon,
  Sidebar
} from 'semantic-ui-react'
import './TopNav.css'
import SideMenu from "../../Components/SideMenu/SideMenu";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
})

const twitteraccount = "https://twitter.com/cheddarpool"

function TopNav (props) {

    return(
      <MediaContextProvider>
         <Media greaterThanOrEqual="sm">
           <TopNavMobile {...props}/>
         </Media>

         <Media greaterThanOrEqual="lg">
           <TopNavDesktop {...props}/>
         </Media>
       </MediaContextProvider>
   )
}

function TopNavMobile (props) {
    const [visible, setVisible] = useState(false);

    const dispatch = useDispatch();

    const test = () => {
      dispatch(toggleSidebar());
      console.log(props.sidebar)
    }

    return(
      <div>
      <SideMenu/>

    <Sidebar.Pusher dimmed={props.sidebar} >
    <Menu fixed='top' size='large' inverted secondary style={{background:"#000000"}}>
      <Container>
          <Menu.Item>
          <Link to="/" >
          <Header as="h3" className="navheader" >

            <Image size='mini' src={`${process.env.PUBLIC_URL}/assets/img/cheese-flat.png`} />

              Stack Cheddar
            </Header>
            </Link>
          </Menu.Item>

          <Menu.Menu position='right'>
          <Menu.Item className="link item" onClick={test}>
          <Icon name='bars' / >
          </Menu.Item>
          </Menu.Menu>

      </Container>
    </Menu>
    </Sidebar.Pusher>
    </div>
   )
}

function TopNavDesktop (props) {

    const dispatch = useDispatch();

    return(
    <Menu fixed='top' size='large' inverted secondary style={{background:"#000000"}}>
      <Container>
        <Menu.Item>
        <Link to="/" >
        <Header as="h4" className="navheader" >
          <Image size='mini' src={`${process.env.PUBLIC_URL}/assets/img/cheese-flat.png`} />
          Stack Cheddar
        </Header>
        </Link>
        </Menu.Item>

        <Menu.Menu position='right'>
          <Menu.Item  href={twitteraccount}>
          <Icon link name='twitter'/ >
          </Menu.Item>
          <Menu.Item >
          <Link to="/about">
            About
            </Link>
          </Menu.Item>
          {props.authenticated ? (
          <React.Fragment>
            <Menu.Item  onClick={()=>dispatch(logout())}>

              Logout

            </Menu.Item>

            <Menu.Item  >
              <Link to="/status">
              Status
              </Link>
            </Menu.Item>

            </React.Fragment>
            ):(
            <Menu.Item>
              <Link to="/login" >
              Login
              </Link>
            </Menu.Item>

          )
          }
        </Menu.Menu>

      </Container>
    </Menu>
   )
}

const mapState = state => {

  return {
    open: state.main.open,
    sidebar: state.main.sidebar,
    authenticated: state.auth.token !== null,

  };
};

const mapDispatch = dispatch => {
  return {
    toggleModal: () => dispatch(toggleModal()),
    toggleSidebar: () => dispatch(toggleSidebar()),
    logout: () => dispatch(logout()),

  };
};

export default connect(mapState, mapDispatch)(TopNav)
