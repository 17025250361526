import React from 'react'
import { connect } from "react-redux";

import { toggleModal } from "../../Store/actions/main";
import { Button, Header, Modal, List, Card } from 'semantic-ui-react'

function HowTo(props) {

  return (
    <Modal open={props.open} dimmer='blurring'>
      <Modal.Header>How To Delegate</Modal.Header>
      <Modal.Content image>

        <Modal.Description>
        <List ordered size='big'>
           <List.Item>Buy Cardano Coins (ADA)</List.Item>
           <List.Item>Transfer your ADA to <a href="https://daedaluswallet.io/">Deadalus Wallet </a>
           or
           <a href="https://daedaluswallet.io/"> Yoroi Wallet</a>
           </List.Item>
           <List.Item>Go to the staking/delegation section and look for:</List.Item>
           <Card fluid>
           <Card.Content>
              <Header as='h4'>
                CHDR
                <Header.Subheader>
                  ticker
                </Header.Subheader>
              </Header>
              <Header as='h4' style={{ overflowWrap: 'break-word' }}>
                pool1hd5r62ktu43k53tskpr8xja8pyt5k6vws2fxylqm7hp5ye954ea
                <Header.Subheader>
                  pool ID
                </Header.Subheader>
              </Header>

           </Card.Content>
           </Card>
           <List.Item>Hit delegate and choose the wallet you want to use for staking.</List.Item>
        </List>

        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.toggleModal()} color='black' >
          Got it!
        </Button>

      </Modal.Actions>
    </Modal>
  )
}

const mapState = state => {
  return {
    open: state.main.open,
  };
};

const mapDispatch = dispatch => {
  return {
    toggleModal: () => dispatch(toggleModal()),
  };
};

export default connect(mapState, mapDispatch)(HowTo)
