import React from 'react'
import {
  Header,
  Grid,
  Image,
} from "semantic-ui-react";
import './MainTitle.css'
import { animated, useSpring} from 'react-spring'


export function MainTitle () {
    const { x } = useSpring({ from: { x: 0 }, x: 1, config: { duration: 1000 } })

    return(
      <div >
        <animated.div id="introHeader"
          style={{
            opacity: x.interpolate({ range: [0, 1], output: [0.3, 1] }),
            transform: x
              .interpolate({
                range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
              })
              .interpolate(x => `scale(${x})`)
          }}>
    <Grid verticalAlign="middle" container style={{ margin: "0em 5em"}}>
    <Grid.Row columns={2}>

    <Grid.Column width ={4} textAlign="right" >
    <Image className="main-logo" src={`${process.env.PUBLIC_URL}/assets/img/cheese-flat.png`}  />
    </Grid.Column>

    <Grid.Column width ={12} textAlign="left" >
    <Header as="h1" className="main-header">
    Stack Cheddar
    </Header>
    <Header as="h3" className="sub-header">Cardano stakepool</Header>
    </Grid.Column>

    </Grid.Row>
    </Grid>
    </animated.div>
  </div>
  )
}
